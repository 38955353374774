import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1 from "../../images/s-series/timberline-s-series-17-tr-12g-15g-tanks.jpg"
import Image2 from "../../images/TIMBERLINE-S-SERIES-17-D-front-side-WEB-THUMB.jpg"
import Image3 from "../../images/TIMBERLINE-S-SERIES-17-D-front-side-back-WEB-THUMB.jpg"
import Image4 from "../../images/TIMBERLINE-S-SERIES-17-D-front-side-back-twist-WEB-THUMB.jpg"

import Image1Large from "../../images/s-series/timberline-s-series-17-tr-12g-15g-tanks.jpg"
import Image2Large from "../../images/TIMBERLINE-S-SERIES-17-D-front-side-WEB-Large.jpg"
import Image3Large from "../../images/TIMBERLINE-S-SERIES-17-D-front-side-back-WEB-Large.jpg"
import Image4Large from "../../images/TIMBERLINE-S-SERIES-17-D-front-side-back-twist-WEB-Large.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }

  #available-text {
    position: fixed;
    right: -120px;
    top: 90px;
    padding: 2rem;
    font-size: 2em;
    width: 600px;
    text-align: center;
    background-color: red;
    color: white;
    z-index: 2;
    box-shadow: 5px 10px 10px #888888;

    -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari prior 9.0 */
    transform: rotate(30deg); /* Standard syntax */
    transition-duration: 0.5s;

    @media (max-width: 1570px) {
      right: -200px;
      font-size: 1.3em;
    }

    @media (max-width: 1400px) {
      position: sticky;
      /* right: 0; */
      /* height: 20px; */
      /* padding-top: 1500px; */

      font-size: 1.5em;
      /* top: 150px; */
      margin: 0;
      padding: 0.7rem;
      -ms-transform: rotate(360deg); /* IE 9 */
      -webkit-transform: rotate(360deg); /* Safari prior 9.0 */
      transform: rotate(360deg);
      width: 100%;
    }
  }
`

const S17DPage = () => {
  return (
    <>
      <SEO title="S17 Floor Scrubber | Timberline Cleaning Equipment" />
      <Helmet>
        <title>S17 Floor Scrubber | Timberline Cleaning Equipment</title>
        <description>
          Compact but ruggedly designed, the S17 Floor Scrubber cleans in hard
          to reach areas with its small footprint. Maneuver through busy
          Hospital Rooms or Senior Living Facilities with care. This scrubber is
          best suited in tighter working environments under 25,000ft2 (2,332m2).
          The 17-inch (43cm) disk style scrub brush is installed and removed
          without tools to allow ease when switching between different brushes
          or pads, depending upon the application’s needs. The squeegee blades
          can be rotated or flipped without tools. Nimble enough to clean
          congested areas with ease, but durable enough to last years.
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          {/* <p id="available-text">Available Fall 2020</p> */}
          <div id="products-layout">
            <ImagePicker
              image1={Image1}
              image2={Image2}
              image3={Image3}
              image4={Image4}
              image1Large={Image1Large}
              image2Large={Image2Large}
              image3Large={Image3Large}
              image4Large={Image4Large}
              mobileLink="#demo-section"
              mobileTitle="S17 Disk"
            />
            <ProductDescription
              productTitle="S17 Disk"
              subHeading="Walk-Behind Small Floor Scrubbers"
              modelNum="S17D"
              productDescription="Compact but ruggedly designed, the S17 Floor Scrubber cleans in hard to reach areas with its small footprint.  Maneuver through busy Hospital Rooms or Senior Living Facilities with care.  This scrubber is best suited in tighter working environments under 25,000ft2 (2,332m2).  The 17-inch (43cm) disk style scrub brush is installed and removed without tools to allow ease when switching between different brushes or pads, depending upon the application’s needs.  The squeegee blades can be rotated or flipped without tools.  Nimble enough to clean congested areas with ease, but durable enough to last years."
              link="#demo-section"
              list1="Constructed robustly — steel frame construction and durable components "
              list2="Simplify maintenance with easy access to the battery and recovery tank"
              list3="Traction drive speed controlled with easy to use Drive Speed Control thumb dial"
              list4="Easily operate and maneuver with push button forward and toggle reverse"
              list5="Clean any hour of the day with approved sound levels < 67 dBA"
              list6="Increase productivity with over 2 hours of run time between dump and refill"
              list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
              brochure="../../timberline-walk-behind-sm.pdf"
              tech="../../tl-s-series-tech-specs-v21263.pdf"
              parts="https://www.dropbox.com/s/dn19xr5z8j2ri4r/TL-S-SERIES-P-EN.pdf?dl=0"
              operator="https://www.dropbox.com/s/kvk65pc1qrwflpp/T-S-SERIES-OP-EN.pdf?dl=0"
              hood="../../timberline-underthehood.pdf"
              pm="../../S-Series-PM.pdf"
              mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
              />
          </div>
          <div id="demo-section"></div>
          <IndexSection3 title="Request a Free Demo at your Facility" />
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default S17DPage
